<template>
  <div>
    <v-card class="remind__container mx-auto">
      <v-card-text v-if="!send">
        <div class="container text-center mt-2">
          <img class="logo" :src="logoSvg" />
        </div>
        <h1 class="remind__info-text ma-6 ma-lg-16 mt-lg-8" v-html="$t('forget_password')"></h1>
        <subtitle-1 class="mt-12 ml-6 mr-6 ml-lg-16 mr-lg-16 row remind__info">{{ $t('forget_password_info') }}</subtitle-1>
        <form class="ma-6 ma-lg-16" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" sm="12" v-if="!send">
              <v-text-field
                :label="$t('client_number')"
                class="remind__input"
                outlined
                required
                autofocus
                v-model="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="text-center">
              <button
                type="submit"
                class="main-button main-button-fixed"
              >{{ $t('send_new_password') }}</button>
            </v-col>
            <v-col cols="12" lg="6" class="text-center text-lg-right grey-link">
              <router-link :to="{name: 'Login'}">{{ $t('return_to_login_capitalize') }}</router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
      <v-card-text v-if="send">
        <div class="container text-center mt-2">
          <img class="logo" :src="logoSvg" />
        </div>
        <h1 class="remind__info-text mt-6 ml-6 mr-6 mt-lg-16 ml-lg-16 mr-lg-16 mt-lg-8" v-html="$t('send_title')"></h1>
        <subtitle-1 class="mt-12 ml-6 mr-6 ml-lg-16 mr-lg-16 row remind__info" v-html="$t('send_info')"></subtitle-1>
        <form class="ma-6 ma-lg-16" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" lg="6" class="text-center" v-if="!send">
              <v-text-field
                :label="$t('client_number')"
                class="remind__input"
                outlined
                required
                autofocus
                v-model="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="text-center">
              <router-link :to="{name: 'Login'}">
                <button
                  type="submit"
                  class="main-button main-button-fixed"
                >{{ $t('return_to_login_capitalize') }}</button>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'remindPassword',
  data () {
    return {
      login: '',
      send: false
    }
  },
  computed: {
    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    }
  },
  methods: {
    submit () {
      this.send = true
      this.$http.post('/auth/send_reset_password/', { login: this.login })
        .then(response => console.log(response))
    }
  }
}
</script>

<style lang="scss">
  .remind__container {
    width: 574px;
    min-height: 586px;
    border-radius: 20px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 2px solid transparent !important;
  }

  .remind__info-text {
    line-height: 1.55;
    text-align: left;
  }

  .remind__info {
    color: #3D3D3F;
  }

  .remind__input {
    fieldset {
      color: transparent !important;
    }

    .v-input__slot {
      background-color: rgba(180, 180, 180, 0.1) !important;
      border-radius: 10px;
      border: none;
    }
  }
</style>
